import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select';

export default class extends Controller {
  // static values = {
  //   options: {
  //     closeOnSelect: true
  //   }
  // };

  connect() {
    // console.log("hello from slim");
    const e = this.element;
    // console.log(e);
    if (e instanceof HTMLSelectElement) {
      // console.log(e instanceof HTMLSelectElement);
      this.selectField = e;
      // console.log(this.selectField);
    } else {
      this.selectField = e.querySelector('select');
      // console.log(this.selectField);
    }
    // const multiple = this.element.multiple
    // console.log(multiple)
    // if (multiple) {
    //   this.options = {
    //     allowDeselect: true,
    //     hideSelectedOption: true,
    //     closeOnSelect: true
    //   };
    // } else {
    //   this.options = {
    //     allowDeselect: true,
    //     hideSelectedOption: true,
    //     closeOnSelect: true
    //   };
    // }
      this.options = {
        allowDeselect: true,
        deselectLabel: '<span class="text-primary">x</span>',
        hideSelectedOption: true,
        closeOnSelect: true,
        searchText: 'aucun résultat',
        searchPlaceholder: 'recherche',
      };
    if (this.selectField) {
      this.slimselect = new SlimSelect({
        select: this.selectField,
        ...this.options,
      });
    }
  }

  disconnect() {
    this.slimselect.destroy();
  }
}
