import { Controller } from "@hotwired/stimulus"
import Swiper, { Navigation, Pagination } from "swiper";

export default class extends Controller {

  static values = {
    format: String,
  }

  connect () {
    // let this.mySwiper;
    // if (this.formatValue == 'wallet-all') {
    //   let mediaQuery = window.matchMedia( '(min-width:980px)' );
    //   // console.log(`break = ${mediaQuery.matches}`);
    //   this.breakpointChecker(mediaQuery);
    //   mediaQuery.addListener( () => {
    //     this.breakpointChecker(mediaQuery);
    //   });
    // }
    if (this.formatValue == 'mailbox') {
      this.initMailboxSwiper();
    }
    if (this.formatValue == 'inbox') {
      this.initInboxSwiper();
    }
    if (this.formatValue == 'newsbox') {
      this.initNewsboxSwiper();
    }
    if (this.formatValue == 'favbox') {
      this.initFavBoxSwiper();
    }
    if (this.formatValue == 'wallet-mobile') {
      this.initCardsSwiper();
    }
    if (this.formatValue == 'wallet-desk') {
      this.initCoverFlowSwiper();
    }
    if (this.formatValue == 'suggestions') {
      this.initSuggestionsSwiper();
    }
    if (this.formatValue == 'screenshots') {
      this.initScreenshotsSwiper();
    }
    // document.addEventListener('append-slides', () => {
    //   this.swiper.appendSlides(new SwiperSlide('.new-slide'));
    // });
  }

  breakpointChecker(e) {
    // if larger viewport and multi-row layout needed
    // console.log(e);
    if ( e.matches === true ) {
      // clean up old instances and inline styles when available
      if ( this.swiper !== undefined ) this.destroySwiper(this.swiper);
      // or/and do nothing
      return this.initCoverFlowSwiper();
      // return;
    // else if a small viewport and single column layout needed
    } else if ( e.matches === false ) {
      if ( this.swiper !== undefined ) this.destroySwiper(this.swiper);
      // fire small viewport version of swiper
      return this.initCardsSwiper();
    }
  }

  initCardsSwiper() {
    Swiper.use([Navigation, Pagination]);
    const optionsSwiper = {
      // effect: "cards",
      grabCursor: true,
      loop: false,
      freeMode: false,
      // freeMode: true,
      slidesPerView: "auto",
      // slidesPerView: 3,
      centeredSlides: true,
      spaceBetween: 8,
    };
    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...optionsSwiper
    });
    // console.log(swiper);
  }

  initCardsDeckSwiper() {
    Swiper.use([Navigation, Pagination]);
    // console.log(Swiper);
    // const optionsPagination = { navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' } };
    const optionsSwiper = {
      effect: "cards",
      grabCursor: true,
      loop: false,
      slidesPerView: 1,
      spaceBetween: 0,
      // Responsive breakpoints
      // breakpoints: {
      //   // when window width is >= 320px
      //   // 320: {
      //   //   slidesPerView: 2,
      //   //   spaceBetween: 20
      //   // },
      //   // when window width is >= 480px
      //   480: {
      //     slidesPerView: 1,
      //     spaceBetween: 0
      //   },
      //   // when window width is >= 640px
      //   640: {
      //     slidesPerView: 2,
      //     spaceBetween: 20
      //   },
      //   // when window width is >= 800px
      //   1200: {
      //     slidesPerView: 3,
      //     spaceBetween: 20
      //   }
      // },
      // virtual: {
      //   slides: (function () {
      //     const slides = [];
      //     for (var i = 0; i < 600; i += 1) {
      //       slides.push('Slide ' + (i + 1));
      //     }
      //     return slides;
      //   })(),
      // }
      // If we need pagination
      // pagination: {
      //   el: '.swiper-pagination',
      // },
      // Navigation arrows
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // }
    };
    // console.log(optionsSwiper);
    // console.log(this.defaultOptions);
    // console.log(this.element);
        // this.swiper
    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...optionsSwiper
    });
    // console.log(swiper);
  }

  initCoverFlowSwiper() {
    Swiper.use([Navigation, Pagination]);
    // console.log(Swiper);
    const optionsSwiper = {
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 250,
        modifier: 1,
        slideShadows: true,
      },
      pagination: {
        el: ".swiper-pagination",
      },
      loop: false,
      slidesPerView: 1.5,
      spaceBetween: 30,
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 320px
        // 320: {
        //   slidesPerView: 2,
        //   spaceBetween: 20
        // },
        // when window width is >= 480px
        // 480: {
        //   slidesPerView: 1,
        //   spaceBetween: 0
        // },
        // // when window width is >= 640px
        // 640: {
        //   slidesPerView: 2,
        //   spaceBetween: 20
        // },
        // when window width is >= 800px
        // 1200: {
        //   slidesPerView: 3,
        //   spaceBetween: 10
        // },
        // 1600: {
        //   slidesPerView: 3,
        //   spaceBetween: 20
        // },
        // 1900: {
        //   slidesPerView: 3,
        //   spaceBetween: 30
        // }
      },
    };
    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...optionsSwiper
    });
    // console.log(swiper);
  }

  initSuggestionsSwiper() {
    Swiper.use([Navigation, Pagination]);
    // console.log(Swiper);
    // const optionsPagination = { navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' } };
    const optionsSwiper = {
      // Optional parameters
      // direction: 'horizontal',
      loop: true,
      freeMode: true,
      grabCursor: true,
      speed: 3000,
      direction: 'horizontal',
      autoplay: {
        delay: 0,
        waitForTransition: false,
        reverseDirection: false
        // disableOnInteraction: false,
      },
      // lazyLoading: true,
      // centeredSlides: true,
      // Default parameters
      centeredSlides: false,
      slidesPerView: 'auto',
      spaceBetween: 20,
      // Responsive breakpoints
      // breakpoints: {
      //   // when window width is >= 320px
      //   320: {
      //     slidesPerView: 1,
      //     spaceBetween: 10
      //   },
      //   // when window width is >= 480px
      //   480: {
      //     slidesPerView: 2,
      //     spaceBetween: 10
      //   },
      //   // when window width is >= 640px
      //   640: {
      //     slidesPerView: 3,
      //     spaceBetween: 20
      //   },
      //   // when window width is >= 800px
      //   800: {
      //     slidesPerView: 3,
      //     spaceBetween: 30
      //   },
      //   // when window width is >= 800px
      //   1100: {
      //     slidesPerView: 4,
      //     spaceBetween: 30
      //   },
      //   // when window width is >= 800px
      //   1500: {
      //     slidesPerView: 6,
      //     spaceBetween: 30
      //   }
      // },
      // virtual: {
      //   slides: (function () {
      //     const slides = [];
      //     for (var i = 0; i < 600; i += 1) {
      //       slides.push('Slide ' + (i + 1));
      //     }
      //     return slides;
      //   })(),
      // }
      // If we need pagination
      // pagination: {
      //   el: '.swiper-pagination',
      // },
      // Navigation arrows
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // }
    };
    // console.log(optionsSwiper);
    // console.log(this.defaultOptions);
    // console.log(this.element);
    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...optionsSwiper
    });
    // console.log(swiper);
  }

  // initAdsLeaderboardSwiper() {
  //   Swiper.use([Navigation, Pagination]);
  //   const optionsSwiper = {
  //     loop: true,
  //     freeMode: true,
  //     grabCursor: true,
  //     speed: 20000,
  //     direction: 'horizontal',
  //     autoplay: {
  //       delay: 0,
  //       // waitForTransition: false,
  //       reverseDirection: false,
  //       disableOnInteraction: false,
  //     },
  //     centeredSlides: false,
  //     slidesPerView: 'auto',
  //     spaceBetween: 0,
  //   };
  //   this.swiper = new Swiper(this.element, {
  //     ...this.defaultOptions,
  //     ...optionsSwiper
  //   });
  // }

  initScreenshotsSwiper() {
    Swiper.use([Navigation, Pagination]);
    // console.log(Swiper);
    // const optionsPagination = { navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' } };
    const optionsSwiper = {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      freeMode: false,
      lazyLoading: true,
      keyboard: {
        enabled: true
      },
      centeredSlides: true,
      slidesPerView: 1.5,
      spaceBetween: 20,
    };
    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...optionsSwiper
    });
  }

  initFavBoxSwiper() {
    Swiper.use([Navigation, Pagination]);
    const optionsSwiper = {
      // Optional parameters
      direction: 'horizontal',
      loop: false,
      freeMode: false,
      grabCursor: true,
      // lazyLoading: true,
      keyboard: {
        enabled: true
      },
      centeredSlides: true,
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next-favbox',
        prevEl: '.swiper-button-prev-favbox'
      }
    };
    this.swiper = new Swiper(".swiper.favbox", {
      ...this.defaultOptions,
      ...optionsSwiper
    });
  }

  initNewsboxSwiper() {
    Swiper.use([Navigation, Pagination]);
    const optionsSwiper = {
      // Optional parameters
      direction: 'horizontal',
      loop: false,
      freeMode: false,
      grabCursor: true,
      // lazyLoading: true,
      keyboard: {
        enabled: true
      },
      centeredSlides: true,
      // slidesPerView: 'auto',
      slidesPerView: 1,
      spaceBetween: -2,
      navigation: {
        nextEl: '.swiper-button-next-newsbox',
        prevEl: '.swiper-button-prev-newsbox'
      },
      threshold: (screen.availWidth) / 60
      // on: {
      //   click: function(){
      //     console.log(this.clickedSlide);
      //   },
      //   scroll: function(){
      //     console.log('test scroll');
      //   }
      // },
    };
    this.swiper = new Swiper(".swiper.newsbox", {
      ...this.defaultOptions,
      ...optionsSwiper
    });
  }


  initInboxSwiper() {
    Swiper.use([Navigation, Pagination]);
    const optionsSwiper = {
      // Optional parameters
      direction: 'horizontal',
      loop: false,
      freeMode: false,
      grabCursor: true,
      // lazyLoading: true,
      keyboard: {
        enabled: true
      },
      centeredSlides: true,
      slidesPerView: 1,
      spaceBetween: -2,
      navigation: {
        nextEl: '.swiper-button-next-inbox',
        prevEl: '.swiper-button-prev-inbox'
      },
      threshold: (screen.availWidth) / 60
      // on: {
      //   click: function(){
      //     console.log(this.clickedSlide);
      //   },
      //   scroll: function(){
      //     console.log('test scroll');
      //   }
      // },
    };
    this.swiper = new Swiper(".swiper.inbox", {
      ...this.defaultOptions,
      ...optionsSwiper
    });
  }

  initMailboxSwiper() {
    Swiper.use([Navigation, Pagination]);
    const optionsSwiper = {
      // Optional parameters
      direction: 'horizontal',
      loop: false,
      freeMode: false,
      grabCursor: true,
      // lazyLoading: true,
      keyboard: {
        enabled: true
      },
      centeredSlides: true,
      slidesPerView: 1,
      spaceBetween: -2,
      navigation: {
        nextEl: '.swiper-button-next-mailbox',
        prevEl: '.swiper-button-prev-mailbox'
      },
      threshold: (screen.availWidth) / 60
      // on: {
      //   click: function(){
      //     console.log(this.clickedSlide);
      //   },
      //   scroll: function(){
      //     console.log('test scroll');
      //   }
      // },
    };
    this.swiper = new Swiper(".swiper.mailbox", {
      ...this.defaultOptions,
      ...optionsSwiper
    });


    // console.log(this.swiper.enabled);
    // // active slide with onstage id
    // const indexOnStage = this.getSlideIndexById(onstage) || 0;
    // this.swiper.on('afterInit', function(){
    //   this.slideTo(indexOnStage);
    // });

    // since we use custome nav buttons we have to manage state
    // this.swiper.on('reachEnd', function(){
    //   console.log(this.navigation);
    //   // this.nextTarget.classList.add('swiper-button-disabled');
    //       //   navigation: {
    // //     nextButton: this.nextTarget,
    // //     prevButton: this.prevTarget,
    // //   },
    // });
    // // this.swiper.on('slideNextTransitionEnd',function(){
    // //     if($("#slide-next-button-unique").hasClass("swiper-button-disabled")){
    // //         $("#slide-next-button").removeClass("swiper-button-disabled");
    // //         $("#slide-next-button").attr("aria-disabled", "false");
    // //     }
    // // })
  }

  disconnect () {
    if (this.swiper) {
      this.destroySwiper(this.swiper);
    }
  }

  get defaultOptions () {
    return {}
  }

  destroySwiper(swiper) {
    swiper.destroy();
    swiper = undefined;
    // console.log(`clean = ${swiper}`);
  }

  reloadSwiper() {
    console.log(this.swiper);
    this.swiper.update();
  }

}

      // let appendNumber = 600;
      // let prependNumber = 1;
      // const swiper = new Swiper('.swiper', {
      //   centeredSlides: true,
      //   spaceBetween: 30,
      //   pagination: {
      //     el: '.swiper-pagination',
      //     type: 'fraction',
      //   },
      //   navigation: {
      //     nextEl: '.swiper-button-next',
      //     prevEl: '.swiper-button-prev',
      //   },
      // });


