import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="refresh"
// refresh screen after click waiting request to fetch
// https://onrails.blog/2022/06/24/stimulus-js-hotwire-tutorial-interactive-deletes/
export default class extends Controller {
  static targets = ["content", "frame"]

  connect() {
    // console.log('hello from refresher');
    // console.log(this.contentTarget);
    // console.log(this.frameTarget);
  }

  refreshTarget(event) {
    this.frameTarget.innerHTML = this.contentTarget.innerHTML;
  }
}

