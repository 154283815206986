import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['value']

  connect() {
    const counter = this.valueTarget
    const notification = counter.closest('.unread-notification')
    // console.log(notification);
    // console.log(this.element.dataset.count);
    this.updateNotificationDisplay();
  }

  updateNotificationDisplay() {
    const counter = this.valueTarget
    const notification = counter.closest('.unread-notification')
    const nb = parseInt(counter.innerHTML);
    // console.log(`nb = ${nb}`);
    if (nb > 0) {
      notification.classList.remove('inactive');
    } else {
      notification.classList.add('inactive');
    }
  }

}
