import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="centryc-color"
export default class extends Controller {
  connect() {
    this.class = "centryc-o";
    this.charCode = "99"; // char code of "c" - charCodeAt(x)
    const target = this.element;
    this.reviewCentrycBranding(target);
  }

  // mon&nbsp;<span class="centryc-o">c</span>entre <span class="centryc-o">c</span>ommercial digital

  reviewCentrycBranding(element) {
    const str = element.innerHTML;
    // console.log(str);
    let newStr = "";
    for (let i = 0; i < str.length; i++) {
      let char = str.charAt(i);
      if (this.charCode == str.charCodeAt(i)) {
        char = this.wrapSpan(this.class, char);
      }
      newStr += char;
    }
    if (newStr.length > 0) {
      // console.log(newStr);
      element.innerHTML = newStr;
    }
  }

  wrapSpan(class_color, char){
      return '<span class="' + class_color + '">' + char + '</span>';
  }

  // wrapSpan(colour, char){
  // }

}
