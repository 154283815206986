import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["changing"]   //name of the variable
  static targets = ["content"]

  connect() {
    // console.log('toggle is on');
    // console.log(this.element);
    // console.log(this.changingClass);
    this.class = this.hasChangingClass ? this.changingClass : 'd-none';
  }

  toggle() {
    this.contentTargets.forEach((t) => t.classList.toggle(this.class));
  }

}

// toggle specific class on multiple content (hidden, collapse...)
// <div data-controller="toggle" data-toggle-class="d-none">
//   <button data-action="toggle#toggle">Toggle</button>
//   <div data-toggle-target="content">
//     Some special content
//   </div>
//   <div data-toggle-target="content">
//     Some special content
//   </div>
// </div>
// <div data-controller="toggle" data-toggle-class="hidden">
//   <button data-action="toggle#toggle">Toggle</button>
//   <div data-target="toggle.content">
//     Some special content
//   </div>
// </div>

// toggle active class among tabs
// <div data-controller="toggle" data-toggle-class="active">
//   <div
//     class="tab active"
//     data-action="click->toggle#toggle"
//     data-target="toggle.content"
//   >
//     Tab One
//   </div>
//   <div
//     class="tab"
//     data-action="click->toggle#toggle"
//     data-target="toggle.content"
//   >
//     Tab Two
//   </div>
// </div>

// import { Controller } from '@hotwired/stimulus'

// export default class extends Controller {
//   hasHiddenClass: boolean
//   hiddenClass: string
//   itemTargets: HTMLElement[]
//   class: string

//   static targets = ['item']
//   static classes = ['hidden']

//   connect (): void {
//     this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden'
//   }

//   toggle (): void {
//     this.itemTargets.forEach(item => {
//       item.classList.toggle(this.class)
//     })
//   }

//   show (): void {
//     this.itemTargets.forEach(item => {
//       item.classList.remove(this.class)
//     })
//   }

//   hide (): void {
//     this.itemTargets.forEach(item => {
//       item.classList.add(this.class)
//     })
//   }
// }
// data-toggle-changing-class="d-none"
// <div data-controller="reveal" data-reveal-hidden-class="hidden">
//   <button data-action="click->reveal#toggle" type="button" class="btn">
//     Toggle me!
//   </button>

//   <p data-reveal-target="item" class="hidden mt-4">Hey 👋</p>
//   <p data-reveal-target="item" class="hidden mt-4">You can have multiple items</p>
// </div>
