import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navbar"
export default class extends Controller {
  static targets = ["navbar", "reservation"]
  static classes = ["fixed"]
  static values = {
    reserve: Boolean,
    threshold: Number,
    toggle: Boolean,
    fixedClass: String,
  }

  connect() {
    // if (this.hasNavbarTarget) {
    //   const navbar = this.navbarTarget || this.element;
    //   const reserve = this.reserveValue || false;
    // }
    const navbar = this.element;
    const threshold = this.thresholdValue || 1;
    const toggle = this.toggleValue || false;
    this.class = this.hasFixedClass ? this.fixedClass : 'sticky';
    // console.log(this.thresholdValue);
    if (toggle) {
      this.initAppearedNavbarOnScroll(navbar, threshold);
    // } else if (reserve) {
    //   this.initFixedNavbarWithReserve(navbar);
    } else {
      this.initFixedNavbarOnScroll(navbar, threshold);
    }
  }

  // initFixedNavbarWithReserve(navbar) {
  //   if (navbar && this.hasReservationTarget) {
  //     const reservationHeight = navbar.offsetHeight;
  //     console.log(reservationHeight);
  //     // this.element.style.setProperty('--scroll-padding', reservationHeight + "px" )
  //     this.reservationTarget.style.setProperty("height", reservationHeight + 'px', "important");
  //   }
  // }

  initFixedNavbarOnScroll(navbar, threshold) {
    if (navbar) {
      window.addEventListener('scroll', () => {
        // const condition = window.scrollY >= window.innerHeight;
        const condition = window.scrollY >= threshold;
        if (condition) {
          navbar.classList.add(this.class);
        } else {
          navbar.classList.remove(this.class);
        }
      });
    }
  }

  initAppearedNavbarOnScroll(navbar, threshold) {
    if (navbar) {
      window.addEventListener('scroll', () => {
        // const condition = window.scrollY >= window.innerHeight;
        const condition = window.scrollY >= threshold;
        if (condition) {
          navbar.classList.add(this.class);
          navbar.classList.remove('collapse');
        } else {
          navbar.classList.remove(this.class);
          navbar.classList.add('collapse');
        }
      });
    }
  }

  // add back button in standalone - pwa mode
  addBackButtonToNavBarOnStandalone() {
    const backButton = this.element.querySelector('#sidebar-back')
    if (backButton) {
      backButton.classList.remove('d-none');
      backButton.classList.add('d-flex');
    }
  }

  isInStandaloneMode() {
    ('standalone' in window.navigator) && (window.navigator.standalone);
  }
}
