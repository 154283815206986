import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "menuburger" ]

  open() {
    // console.log(this.element);
    this.element.classList.toggle("open")
  }
}
