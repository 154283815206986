import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="side-swiper"
export default class extends Controller {
  connect() {
    // console.log('hello from side-swipe');
    this.element.addEventListener('touchstart', handleTouchStart,  { passive: false });
    this.element.addEventListener('touchmove', handleTouchMove,  { passive: false });
    let xDown = null;
    let yDown = null;
    const elementToChange = this.element;

    function getTouches(evt) {
      return evt.touches ||             // browser API
             evt.originalEvent.touches; // jQuery
    }

    function handleTouchStart(evt) {
        const firstTouch = getTouches(evt)[0];
        // console.log(firstTouch);
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    };

    function handleTouchMove(evt) {
        if ( ! xDown || ! yDown ) {
            return;
        }

        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;

        let xDiff = xDown - xUp;
        let yDiff = yDown - yUp;
        console.log(xDiff);

        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if ( xDiff > 8 ) {
                /* right swipe */
                // console.log('swiped left!');
                // console.log(elementToChange);
                elementToChange.classList.add('swiped');
                console.log(elementToChange);
            } else if (xDiff < -4) {
                /* left swipe */
                // console.log('swiped right!');
                // console.log(elementToChange);
                elementToChange.classList.remove('swiped');
            }
        } else {
            if ( yDiff > 0 ) {
                /* down swipe */
            } else {
                /* up swipe */
            }
        }
        /* reset values */
        xDown = null;
        yDown = null;
    };

  }


}
