import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['communication', 'connexion', 'metadata', 'screenshot']

  connect() {
    console.log("hello from user website card controller");
  }

  hideAllBoxes() {
    this.constructor.targets.forEach( target => {
      if (target) {
        target.classList.add('d-none');
      }
    });
  }

  toggleElementHideOthers(element) {
    if (element.classList.contains('d-none')) {
      hideAllBoxes();
      element.classList.remove('d-none');
    } else {
      element.classList.add('d-none');
    }
  }

  toggleCommunication() {
    this.toggleElementHideOthers(this.communicationTarget);
  }

  toggleConnexion() {
    this.toggleElementHideOthers(this.connexionTarget);
  }

  toggleMetadata() {
    this.toggleElementHideOthers(this.metadataTarget);
  }

  toggleScreenshot() {
    this.toggleElementHideOthers(this.screenshotTarget)
  }

  toggleShipment() {
    this.toggleElementHideOthers(this.shipmentTarget);
  }


}


