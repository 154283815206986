import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "link", "uw"]

  connect() {
    // console.log(this.linkTarget);
  }

  goto(event) {
    event.preventDefault();
    const link = this.linkTarget;
    const url = link.href;
    // console.log(url);
    const uw = this.uwTarget.innerText;
    // console.log(uw);
    // const myHeaders = new Headers();
    // myHeaders.append('Content-Type', 'image/jpeg');
    // const myInit = { method: 'GET',
    //                headers: myHeaders,
    //                mode: 'cors',
    //                cache: 'default' };
    // const request = new Request(url, myInit);
    // console.log(request);
    // const myURL = request.url;
    // console.log(myURL);
    // window.open(request);
    window.open(url);

    // const getActive = browser.tabs.query({active: true, currentWindow: true});
    // getActive.then(setCookie);

    // setTimeout(function () { window.location.href = myURL }, 500);
    // await window.alert(document.cookies)
    // "Set-Cookie: session=your_session; SameSite=None; Secure"
    // Window.location = url;
    // Window.location.reload();
  }

}
