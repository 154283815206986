import { Controller } from "@hotwired/stimulus"
// import { initViewerSwipe } from '../drafts/viewer_swipe';

export default class extends Controller {
  static targets = [ 'frame', 'overlay' ]
  static values = {
    secured: Boolean,
    deviceFormat: String
  }

  connect() {
    // console.log("com news controller");
    // console.log(this.deviceFormatValue);
  }

  prepareNewsletterIframe() {
    if (this.hasFrameTarget) {
      const frame = this.frameTarget;
      // console.log(frame.id);

      this.reviewNewsletterLinks(frame);

      this.reviewImagesSource(frame);

      if (this.deviceFormatValue === "phone") {
        const overlay = this.overlayTarget;
        this.adjustViewportAndScreenHeight(overlay, frame);
        this.resizeAndActiveScrollWhenVisible(overlay, frame);
        this.activateEventsManagement(overlay, frame);
      }

      const curtain = this.element;
      curtain.style.opacity = 1;
    }
  }

  prepareIframe() {
    if (this.hasFrameTarget) {
      const frame = this.frameTarget;
      // console.log(frame.id);

      this.checkFrameDOMLoading(frame)

      this.reviewLinks(frame);

      this.reviewImagesSource(frame);

      if (this.deviceFormatValue === "phone"  && this.hasOverlayTarget) {
        const overlay = this.overlayTarget;
        this.adjustViewportAndScreenHeight(overlay, frame);
        this.resizeAndActiveScrollWhenVisible(overlay, frame);
        this.activateEventsManagement(overlay, frame);
      }

      const curtain = this.element;
      curtain.style.opacity = 1;
    }
  }

  // if (frameDoc.readyState === "complete") {
  //     // already fired, so run logic right away
  //     this.runWhenPageIsFullyParsed(overlay, frame);
  // } else {
  //     // not fired yet, so let's listen for the event
  //     frameWindow.addEventListener("DOMFrameContentLoaded", this.runWhenPageIsFullyParsed(overlay, frame));
  // }
  // frameWindow.addEventListener("DOMContentLoaded", onFrameDOMContentLoaded, true);
  // function onFrameDOMContentLoaded () {
  //      console.log("DOMContentLoaded");
  // };

  checkFrameDOMLoading(frame) {
    if (frame.contentWindow.document.readyState === "complete") {
        // already fired, so run logic right away
        // console.log('doc complete');
    } else {
        // not fired yet, so let's listen for the event
        function runWhenPageIsFullyParsed() {
          console.log("DOMFrameContentLoaded");
        }
        frame.contentWindow.addEventListener("DOMFrameContentLoaded",
          runWhenPageIsFullyParsed(), true);
    }
  }


  ////////////////////////////////////////////////
  // links management
  ////////////////////////////////////////////////

  reviewLinks(frame) {
    const links = frame.contentWindow.document.body.querySelectorAll('a');
    if (links.length > 0) {
      links.forEach(link => {
        this.linkOpenInNewTab(link);
      });
    }
    // console.log('review links : '+ links.length);
  }

  reviewNewsletterLinks(frame) {
    const links = frame.contentWindow.document.body.querySelectorAll('a');
    // console.log(links);
    if (links.length > 0) {
      links.forEach(link => {
        this.linkDisabled(link);
      });
    }
  }

  linkOpenInNewTab(linkElement) {
    linkElement.setAttribute('target', '_blank');
  }

  linkReplaceUnsecure(linkElement) {
    linkElement.href = linkElement.href.replace('http://','https://');
  }

  linkRemoveCustomParams(linkElement) {
    const [base, queryString] = linkElement.href.split('?');
    linkElement.href = base;
  }

  linkDisabled(linkElement) {
    linkElement.classList.add('disabled');
    linkElement.removeAttribute('href');
  }

  ////////////////////////////////////////////////
  // images management
  ////////////////////////////////////////////////

  reviewImagesSource(frame) {
    const images = frame.contentWindow.document.body.querySelectorAll('img');
    if (images.length > 0) {
      images.forEach(img => {
        // free cdn to secure image link
        img.src = img.src.replace('http://','https://images.weserv.nl/?url=http://');
        // console.log(img.src);
      });
    }
    // console.log('review images : '+ images.length);
  }

  ////////////////////////////////////////////////
  // size management
  ////////////////////////////////////////////////

  adjustViewportAndScreenHeight(overlay, frame) {
    this.updateMetaViewport(frame)
    // adjust height of frame and overlay to window size
    const sh = window.screen.availHeight;
    // console.log('screen heigh : ' + sh);
    // frame.style.height = sh + 'px';
    // overlay.style.height = sh + 'px';
  }

  resizeAndActiveScrollWhenVisible(overlay, frame) {
    // trigger visibility
    const options = {
      threshold: 0,
      rootMargin: '0px'
    }
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.resizeAndAdjustHeight(overlay, frame)
          observer.unobserve(entry.target)
        }
      })
    }, options)
    observer.observe(this.element)
    // activate scroll
    this.element.style.overflow = "auto";
  }

  resizeAndAdjustHeight(overlay, frame) {
    // scale content if wider than screen
    const cw = frame.contentWindow.document.body.scrollWidth + 20;
    const sw = window.screen.availWidth;
    // 20 px for scroll bar margin
    if (cw > (sw + 20) && sw > 0) {
      this.rescaleFrameWithWidth(frame);
    } else {
      // set frame height to active scroll
      const sh = window.screen.availHeight;
      const ch = frame.contentWindow.document.body.scrollHeight;
      // console.log('ch = '+ ch + 'vs sh = '+ sh);
      frame.style.height = Math.max(sh, ch) + 'px';
    }
    // readjust overlay
    this.resizeOverlayFromFrame(overlay, frame);
  }

  resizeOverlayFromFrame(overlay, frame) {
    const he = window.getComputedStyle(frame).height;
    if (he && he !== 'auto') {
      const heightValue = Number(he.slice(0, -2)); // clean 'px'
      if ( heightValue && heightValue > 800 ) {
        overlay.style.setProperty("height", heightValue + 'px', "important");
      }
    }
  }

  rescaleFrameWithWidth(frame) {
    // compute height of content
    const sh = window.screen.availHeight;
    const ch = frame.contentWindow.document.body.scrollHeight;
    console.log('ch = '+ ch + 'vs sh = '+ sh);
    let newHeight = Math.max(sh, ch);

    // compute width of content
    const cw = frame.contentWindow.document.body.scrollWidth + 20;
    const sw = window.screen.availWidth;
    console.log('cw = '+ ch + 'vs sw = '+ sh);

    // rescale
    let scaleFactor = 1;
    scaleFactor = 1 / (cw / sw);
    console.log("rescale");
    console.log(scaleFactor);
    frame.style.width = cw + 'px';
    frame.style.height = ch + 'px';
    console.log('ch = '+ frame.style.height);
    // console.log('frame w = ' + frame.style.width);
    // console.log('frame h = ' + frame.style.height);
    frame.style.transform = 'scale(' + scaleFactor + ')';
    frame.style.transformOrigin = "0 0";
    // console.log('frame w = ' + frame.style.width);
    // console.log('frame h = ' + frame.style.height);
    const scaleHeight = ch * scaleFactor
    console.log("scale h = " + scaleHeight);
    newHeight = Math.max(scaleHeight, sh);
    console.log("new h = " + newHeight);
    // keep initial height to ensure visibility
    frame.style.height = ch + 'px';
    console.log("final h = " + frame.style.height );
  }

  updateMetaViewport(frame) {
    // get meta dont work
    // check meta params
    const viewPortMeta = this.getMeta(frame, 'viewport');
    const viewPortContent = 'width=device-width, initial-scale=1.0';
      // , maximum-scale=1.0, user-scalable=0
    if (viewPortMeta) {
      viewPortMeta.setAttribute('content', viewPortContent);
    } else {
      const  metaTag = document.createElement('meta');
      metaTag.name = "viewport"
      metaTag.content = viewPortContent
      const head = frame.contentWindow.document.getElementsByTagName('head')[0];
      head.appendChild(metaTag);
    }
  }

  getMeta(frame, metaName) {
    const metas = frame.contentWindow.document.getElementsByTagName('meta');
    // console.log('metas : ' + metas.length);
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') === metaName) {
        // return metas[i].getAttribute('content');
        return metas[i];
      }
    }
    return null;
  }

  ////////////////////////////////////////////////
  // events management
  ////////////////////////////////////////////////

  activateEventsManagement(overlay, frame) {
    // with swiper js
    this.dispatchClickEventsFromOverlayToIframe(overlay, frame);
    // this.dispatchWheelEventsFromOverlayToIframe(overlay, frame);
    // this.dispatchTouchEventsFromOverlayToIframe(overlay, frame);
    // grab event unmanaged

    // with custom swiper
    // if (this.securedValue) {
    //   overlay.style.cssText = 'visibility: hidden';
    //   this.addScriptViewerSwiperToFrame(frame);
    // }

    // const body = frame.contentWindow.document.getElementsByTagName('body')[0];
    // console.log(body);
    // redirectEvent("click", overlay, body);
  }

  dispatchClickEventsFromOverlayToIframe(overlay, frame) {
    // console.log('add event listener to overlay');
    const doc = frame.contentWindow.document;
    let handleClick = (ev) => {
      const x = ev.offsetX;
      const y = ev.offsetY;
      const link = doc.elementFromPoint(x,y);
      // console.log("iframe overlay click at position " + x + "," + y);
      // console.log("iframe link element: ", link);
      let newEvent = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          clientX: x,
          clientY: y
      });
      link.dispatchEvent(newEvent);
    };
    overlay.addEventListener('click',
      (ev) => {
        handleClick(ev);
      },
      { passive: true }
    );
  }

  dispatchWheelEventsFromOverlayToIframe(overlay, frame) {
    let handleWheel = (ev) => {
      // disable overlay pointers on wheel events
      // overlay.style.cssText = "pointer-events:none !important;";
      // setTimeout(function(){
      //   overlay.style.cssText = "pointer-events:auto;";
      // }, 500)

      console.log(ev);
      console.log(ev.target);
      // const iframeWin = frame.contentWindow || frame;
      // const iframeDoc = frame.contentDocument || iframeWin.document;
      // // console.log("iframe overlay scroll from " + x + "," + y + "delta = " + deltaY);
      // let newEvent = new MouseEvent("wheel", { bubbles: true, cancelable: false })
      // newEvent.deltaMode = ev.deltaMode;
      // newEvent.deltaX = ev.deltaX;
      // newEvent.deltaY = ev.deltaY;
      // newEvent.deltaZ = ev.deltaZ;
      // newEvent.wheelDelta = ev.wheelDelta;
      // newEvent.wheelDeltaX = ev.wheelDeltaX;
      // newEvent.wheelDeltaY = ev.wheelDeltaY;
      // console.log(newEvent);
      // newEvent.targetFrame = frame
      // console.log(newEvent);
      // iframeDoc.body.dispatchEvent(newEvent);

      // const x = ev.offsetX;
      // const y = ev.offsetY;
      // const deltaY = ev.deltaY;
      // console.log(ev);
      // console.log("iframe overlay scroll from " + x + "," + y + "delta = " + deltaY);
      // let scroll = new MouseEvent("wheel", {
      //   bubbles: true,
      //   cancelable: false,
      //   clientX: x,
      //   clientY: y,
      //   // deltaY: deltaY,
      //   deltaMode: 0,
      // });
      // scroll.deltaY = 240;
      // scroll.deltaX = 150;
      // console.log(newEvent);
      // console.log(iframeWin);
      // console.log(iframeWin.onwheel);
      // console.log(iframeWin.onmousewheel);
    }
    overlay.addEventListener('wheel',
      (ev) => {
        console.log("listen wheel event");
        handleWheel(ev);
      },
      { passive: true }
    );
  }

  dispatchTouchEventsFromOverlayToIframe(overlay, frame) {
    // let handleTouch = (ev) => {
    //   this.disableEventsOnOverlay(overlay);
    //   // overlay.style = 'visibility: visible';
    // }

    let xDown = null;
    let yDown = null;
    let handleTouchStart = (ev) => {
        const firstTouch = ev.touches[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    };
    let handleTouchMove = (ev) => {
        if ( ! xDown || ! yDown ) {
            return;
        }
        let xUp = ev.touches[0].clientX;
        let yUp = ev.touches[0].clientY;
        let xDiff = xDown - xUp;
        let yDiff = yDown - yUp;
        if ( Math.abs( yDiff ) > Math.abs( xDiff ) ) {
          console.log('hide visibility');
          overlay.style.cssText = "pointer-events:none !important;";
          setTimeout(function(){
            overlay.style.cssText = "pointer-events:auto;";
          }, 1000)
        }
        xDown = null;
        yDown = null;
    };
    overlay.addEventListener('touchstart',
      (ev) => {
        console.log("listen touchstart event");
        handleTouchStart(ev);
      },
      { passive: true }
    );
    // overlay.addEventListener('touchstart', handleTouchStart, false);
    overlay.addEventListener('touchmove',
      (ev) => {
        console.log("listen touchmove event");
        handleTouchMove(ev);
      },
      { passive: false }
    );
    // overlay.addEventListener('touchmove', handleTouchMove, false);
  }

  disableEventsOnOverlay(overlay) {
    overlay.style.cssText = "pointer-events:none !important;";
    setTimeout(function(){
      overlay.style.cssText = "pointer-events:auto;";
    }, 1000)
    // overlay.style.cssText = 'visibility: hidden';
  }

  addScriptViewerSwiperToFrame(frame) {
    const iframeWin = frame.contentWindow || frame;
    const iframeDoc = frame.contentDocument || iframeWin.document;

    const script = iframeDoc.createElement("script");
    script.append(`\
      window.viewerSwipe = function() {\
        const body = document.getElementsByTagName('body')[0];\
        body.addEventListener('touchstart', handleTouchStart, false);\
        body.addEventListener('touchmove', handleTouchMove, false);\
        let xDown = null;\
        let yDown = null;\
        function swipeRight() {\
          const buttonRight = window.frameElement.closest('.modal-content').querySelector('.intercom-nav > .swiper-button-prev-mailbox');\
          buttonRight.click();\
        }\
        function swipeLeft() {\
          const buttonLeft = window.frameElement.closest('.modal-content').querySelector('.intercom-nav > .swiper-button-next-mailbox');\
          buttonLeft.click();\
        }\
        function getTouches(evt) {\
          return evt.touches || evt.originalEvent.touches;\
        }\
        function handleTouchStart(evt) {\
            const firstTouch = getTouches(evt)[0];\
            xDown = firstTouch.clientX;\
            yDown = firstTouch.clientY;\
        };\
        function handleTouchMove(evt) {\
            if ( ! xDown || ! yDown ) {\
                return;\
            }\
            let xUp = evt.touches[0].clientX;\
            let yUp = evt.touches[0].clientY;\
            let xDiff = xDown - xUp;\
            let yDiff = yDown - yUp;\
            const threshold = (screen.availWidth) / 30;\
            if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {\
                if ( xDiff > threshold ) {\
                  swipeLeft();\
                } else if (xDiff < -threshold) {\
                  swipeRight();\
                }\
            }\
            xDown = null;\
            yDown = null;\
        };\
      }`
    );
    iframeDoc.documentElement.appendChild(script);
    iframeWin.viewerSwipe();
  }

  addIframeSwiperOverlay(frame) {
    const ident = 1;
    const overlayId = 'iframe-' + (ident) + '-overlay';
    const overlay = document.createElement("div");
    // position it absolutely so it covers the iframe
    overlay.style.cssText = "position: absolute; top: 0px; left: 0px; opacity: 0; width: 100%; height: 100%; z-index: 400;";
      // .css({
      //   'position': 'absolute',
      //   'top': '0px',
      //   'left': '0px',
      //   'opacity': 0,
      //   'width': '100%',
      //   'height': '100%',
      //   'z-index': 400
      // })

    overlay.classList.add('clickmask');
    overlay.setAttribute('data-controller', "viewer-swiper" );
      // .addClass('clickmask')

    // add event handler to dispatch 'click's to the iframe document
    // overlay.addEventListener('click', function(ev) {
    // // .click(function(ev) {
    //   const doc = frame.contentWindow.document, // iframeDoc,
    //     x = ev.offsetX,
    //     y = ev.offsetY,
    //     link = doc.elementFromPoint(x,y),
    //     // clickev = jQuery.Event('click', { 'offsetX': x, 'offsetY': y });
    //     // clickev = doc.createEvent('HTMLEvents');
    //     // clickev1 = new CustomEvent('click', {detail:{ 'offsetX': x, 'offsetY': y }}),
    //     clickev = doc.createEvent('HTMLEvents');
    //   console.log(link);
    //   clickev.initEvent('click', true, true);
    //   console.log(clickev);
    //   console.log("iframe doc: ", doc);
    //   console.log("iframe overlay click at position " + x + "," + y);
    //   // $('body', doc).trigger(clickev);
    //   // doc.addEventListener('click', clickev, false);
    //   link.dispatchEvent(clickev);
    // });

    // put the overlay div before the iframe element
    // because we absolute position overlay
    const bodyNode = frame.contentWindow.document.getElementsByTagName('body')
    bodyNode[0].style.position = "relative";
    // frame.parent('div').css('position', 'relative');
    // bodyNode[0].appendChild(overlay);
    // frame.parentNode.insertBefore(overlay, frame);
    // frame.insertBefore("beforeBegin", overlay.innerHTML);
    // frame.before(overlay);
    bodyNode[0].setAttribute('data-controller', "viewer-swiper" );
//     const head = frame.contentWindow.document.getElementsByTagName('head')[0];
    const script = frame.contentWindow.document.createElement('script');
    // script.src = document.querySelector("head > script:nth-child(47)").src;
    script.innerText = `initViewerSwipe();`
// function createViewerSwipe() {console.log('hello from viewer-swipe');const element = document.getElementsByTagName('body')[0];\
// console.log(element);element.addEventListener('touchstart', handleTouchStart, false);element.addEventListener('touchmove', handleTouchMove, false);let xDown = null;let yDown = null;};\
// function getTouches(evt) {return evt.touches};function handleTouchStart(evt) {const firstTouch = getTouches(evt)[0];xDown = firstTouch.clientX;yDown = firstTouch.clientY;};function handleTouchMove(evt) {if ( ! xDown || ! yDown ) {return;}let xUp = evt.touches[0].clientX;let yUp = evt.touches[0].clientY;let xDiff = xDown - xUp;let yDiff = yDown - yUp;if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/if ( xDiff > 6 ) {console.log('swiped left!');\
// } else if (xDiff < -6) {console.log('swiped right!');}} else {if ( yDiff > 0 ) {} else {}}xDown = null;yDown = null;}\
// function initViewerSwipe() {alert('swiper');console.log('swiper1');console.log('hello from viewer-swipe');const element = document.getElementsByTagName('body')[0];\
// console.log(element);}\
    script.type = 'text/javascript';
    // bodyNode[0].appendChild(script);
    // window.viewerSwiper = function(element) {
    //    alert(element.scrollWidth);
    // }
    // frame.contentWindow.viewerSwiper(overlay);
  }

  ////////////////////////////////////////////////
  // others
  ////////////////////////////////////////////////

  // injectContent(frame) {
  //   let raw_html = "";
  //   console.log(this.pathValue);
  //   fetch(this.pathValue)
  //     .then(response => response.text())
  //     .then(html => {
  //       raw_html = html;
  //     });
  //   console.log(raw_html.length);
  //   if (raw_html !== "") {
  //     frame.srcdoc = raw_html;
  //   }
  // }

}

function redirectEvent(eventType, fromElement, toElement) {
  // const fromElement = document.querySelector(fromElementSelector);
  // const toElement = document.querySelector(toElementSelector);
  fromElement.addEventListener(eventType, function (event) {
    toElement.dispatchEvent(new event.constructor(event.type, event));
    event.preventDefault();
    event.stopPropagation();
  });
}

