import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ['modal']

  connect() {
    // console.log('modal renderer');
  }

  get modal() {
    return Modal.getOrCreateInstance(this.modalTarget);
  }

  openModalSwiper(evt) {
    const active_id = evt.currentTarget.dataset.onstage;
    // get target slider index
    const indexOnStage = this.getSlideIndexById(active_id) || 0;
    // show modal with bootstrap js
    this.modal.show();
    // active swiper slide with index
    const swiper = this.modalTarget.querySelector('.swiper').swiper;
    // console.log(swiper);
    swiper.slideTo(indexOnStage);
    // console.log(swiper.enabled);
  }

  // search for slides id and get index position
  getSlideIndexById(id) {
    let index = 0;
    const slides = this.modalTarget.querySelectorAll('.swiper-slide');
    slides.forEach( (slide, posIndex) => {
      if (slide.id == id) {
        index = posIndex;
        return false;
      }
    });
    return index;
  }

  closeModal() {
    console.log('hide modal')
    this.modal.hide();
  }

}
