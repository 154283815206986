import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="spa"
export default class extends Controller {
  static classes = ["menu", "unvisible"]   //name of the variable class for hidding elements
  static targets = ["content", "menu"]
  static values = {
    url: String
  }

  connect() {
    this.classUnvisible = this.hasUnvisibleClass ? this.unvisibleClass : 'd-none';
    this.classMenu = this.hasMenuClass ? this.menuClass : 'active';
    this.appContent = this.element.querySelector('#app-content');
    console.log("spa connected");
    this.disconnectAfterInactivity();
    // console.log(this.contentTargets);
    // console.log(this.menuTargets);
    // console.log(this.classVisible);
    // console.log(this.classMenu);
  }

  // https://stackoverflow.com/questions/667555/how-to-detect-idle-time-in-javascript
  disconnectAfterInactivity() {
    let time;
    const url = this.urlValue;
    // back home after 15 min
    const delay = 15 * 60 * 1000 - 1000;
    //const delay = 30000;
    // 1000 milliseconds = 1 second
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;

    function backToHome() {
        // alert("You are now logged out.")
        //location.href = 'logout.html'
        // console.log(url);
        window.location.href = url;
    }

    function resetTimer() {
        clearTimeout(time);
        time = setTimeout(backToHome, delay)
    }
  }

  moveToVisibleAfterDestroy(event) {
    const dataset = event.currentTarget.dataset;
    const idFrameToShow = dataset.visible;
    const idMenuToActive = dataset.menu;
    this.showIdAndHideContents(idFrameToShow, false, null);
    this.activeIdAndInactiveMenus(idMenuToActive);
  }

  showVisibleAndHideContents(event) {
    event.preventDefault();
    const dataset = event.currentTarget.dataset;
    const idFrameToShow = dataset.visible;
    const idMenuToActive = dataset.menu;
    // check if reload needed
    const needReload = dataset.reload == "true";
    const newSrc = dataset.newsrc;
    this.showIdAndHideContents(idFrameToShow, needReload, newSrc);
    this.activeIdAndInactiveMenus(idMenuToActive);

    // if (idFrameToShow == "favbox") {
    //   this.favboxOpenModal();
    // }
    // warning - scroll to top affect all spa !
    const targetIsHub = this.isHub(idFrameToShow);
    if ( targetIsHub ) {
      console.log('scrolling for hub');
      this.scrollToTop();
    }
  }

  showIdAndHideContents(id, reload, newSrc) {
    // console.log(id);
    let frameFound = false;
    this.contentTargets.forEach((t) => {
      if ( id == t.id) {
        // reload turbo frame src to avoid change
        // console.log(t.src == newSrc);
        // const newURL = new URL(newSrc,document.URL).href;
        // console.log(newURL);
        // console.log(t.src == newURL);
        if (reload) {
          t.reload()
        } else if (newSrc && newSrc !== t.src) {
          t.src = newSrc
          t.reload()
        }
        frameFound = true;
        t.classList.remove(this.classUnvisible);
      } else {
        t.classList.add(this.classUnvisible);
      }
    });
    if (!frameFound) {
      this.appendFrameToAppContent(id, newSrc);
    }
  }

  appendFrameToAppContent(frame_id, frame_src) {
    // console.log("append frame");
    // const appContent = this.element.getElementById("app-content");
    const frameHTML = `<turbo-frame class="w-100" id="${frame_id}" data-spa-target="content"
    src="${frame_src}">
    </turbo-frame>`;
    this.appContent.insertAdjacentHTML('beforeend', frameHTML);
  }

  activeIdAndInactiveMenus(id) {
    // console.log(id);
    this.menuTargets.forEach((t) => {
      if (id == t.id) {
        t.classList.add(this.classMenu);
      } else {
        t.classList.remove(this.classMenu);
      }
    });
  }

  // menu actions

  isHub(idFrame) {
    const hubList = ['me', 'communicating_center', 'shopping_center', 'buying_center'];
    return hubList.includes(idFrame);
  }

  scrollToTop() {
    // after visibility actions, scroll to top
    window.scrollTo(0, 0);
  }

  // lockbox actions

  lockboxAllVisible() {
    const lockbox = document.getElementById('lockbox');
    lockbox.querySelectorAll('.cred-password-field').forEach (input => {
      input.type = "text";
    });
  }

  lockboxAllUnvisible() {
    const lockbox = document.getElementById('lockbox');
    lockbox.querySelectorAll('.cred-password-field').forEach (input => {
      input.type = "password";
    });
  }

  // directory actions

  directoryAllScreenshotsVisible() {
    const directory = document.getElementById('directory');
    directory.querySelectorAll('.show-screen').forEach (element => {
      if (!element.classList.contains('d-none')) {
        element.click();
      }
    });
  }

  directoryAllScreenshotsUnvisible() {
    const directory = document.getElementById('directory');
    directory.querySelectorAll('.show-website').forEach (element => {
      if (!element.classList.contains('d-none')) {
        element.click();
      }
    });
  }

  // favbox actions

  favboxOpenModal() {
    const modal = document.getElementById('favbox_user_websites_viewer');
    this.openModal(modal);
  }

  newsboxOpenModal() {
    const modal = document.getElementById('newsbox_newsletters_viewer');
    this.openModal(modal);
  }

  openModal(modal) {
    document.body.classList.add('modal-open');
    modal.style.display = "block";
  }


  // hideContents() {
  //   this.contentTargets.forEach((t) => t.classList.add(this.class));
  // }

  // toggle() {
  //   this.contentTargets.forEach((t) => t.classList.toggle(this.class));
  // }

}

