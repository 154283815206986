import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // successDurationValue: number
  // timeout: number

  static targets = [ "button", "source" ]
  static values = {
    successDuration: {
      type: Number,
      default: 2000
    }
  }

  connect() {
      const element = this.element.querySelector(".clipboard-button.copy");
      if (element) {
        element.classList.add("clipboard--supported");
      }
      const hidden_element = this.element.querySelector(".clipboard-button.copied");
      if (hidden_element) {
        hidden_element.classList.add("clipboard--supported");
      }
  }

  copyFromText() {
    const elementToCopy = this.sourceTarget;
    const originalText = elementToCopy.innerText;
    const buttonCopy = this.element.querySelector(".copy-confirmation");
    this.copyTextToClipboard(originalText);
    this.sourceTarget.blur();
    elementToCopy.classList.add('d-none');
    buttonCopy.classList.remove('d-none');
    setTimeout(function () {
      elementToCopy.classList.remove('d-none');
      buttonCopy.classList.add('d-none');
    }, this.successDurationValue);
  }

  copyFromInput() {
    event.preventDefault();
    const elementToCopy = this.sourceTarget;
    if (elementToCopy.type == "password") {
      elementToCopy.type = "text";
      elementToCopy.select();
      document.execCommand("copy");
      elementToCopy.type = "password";
    } else {
      elementToCopy.select();
      document.execCommand("copy");
    }
    this.sourceTarget.blur();
    this.copied()

    // const check = navigator.clipboard.readText()
    //             .then(text => outputElem.innerText = text);
  }

  copied() {
    this.clearOtherCopyClipboardButtons();
    this.copiedButtonVisible();

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      this.copyButtonVisible();
    }, this.successDurationValue)

    // document.querySelector(".alert .alert-info .alert-dismissible").innerHTML = "copié"
  }

  copiedButtonVisible() {
    const buttonCopy = this.element.querySelector(".clipboard-button.copy");
    const buttonCopied = this.element.querySelector(".clipboard-button.copied");
    if (buttonCopy) {
      buttonCopy.classList.add("d-none");
    }
    if (buttonCopied) {
      buttonCopied.classList.remove("d-none");
    }

  }

  copyButtonVisible() {
    const buttonCopy = this.element.querySelector(".clipboard-button.copy");
    const buttonCopied = this.element.querySelector(".clipboard-button.copied");
    if (buttonCopied) {
      buttonCopied.classList.add("d-none");
    }
    if (buttonCopy) {
      buttonCopy.classList.remove("d-none");
    }
  }


  clearOtherCopyClipboardButtons() {
    // clear others copy clipboard button on page
    document.querySelectorAll(".clipboard-button.copy").forEach( butt => butt.classList.remove("d-none"));
    document.querySelectorAll(".clipboard-button.copied").forEach( butt => butt.classList.add("d-none"));
  }

  // new 2021 copytoclipboard meth

  copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
      // console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      // console.error('Async: Could not copy text: ', err);
    });
  }

  fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      // console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      // console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

}
