import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="requests"
export default class extends Controller {
  connect() {
  }

  stopPendingRequests() {
    window.stop();
  }
}
