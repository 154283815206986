import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['value']

  connect() {
    // const counterValue = this.valueTarget
    // const counterContainer = counterValue.closest('.counter')
    // console.log(this.element.dataset.count);
    this.updateCounterDisplay();
  }

  updateCounterDisplay() {
    const counterValue = this.valueTarget
    // console.log(counterValue);
    const counterContainer = counterValue.closest('.counter-box')
    const nb = parseInt(counterValue.innerHTML);
    // console.log(`nb = ${nb}`);
    if (nb > 0) {
      counterContainer.classList.remove('d-none');
    } else {
      counterContainer.classList.add('d-none');
    }
  }

}
