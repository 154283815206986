import { Controller } from "@hotwired/stimulus"
// import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "errors", "hint", "icon", "input", "label", "wrapper" ]

  connect() {
    // console.log(this.element);
    // if (this.hasInput) {
    //   console.log(this.inputTarget);
    // }
    // console.log(this.errorsTarget);
    // console.log(this.labelTarget);
    // console.log(this.iconTarget);
  }

  // error rendering for modal forms
  onSaveFail(event) {
    // console.log(this.errorsTarget);
    // const errorsDiv = this.errorsTarget;
    // const [data, status, xhr] = event.detail;
    // console.log(xhr.response);
    if (event.detail[0].body) {
      this.errorsTarget.innerHTML = event.detail[0].body.innerHTML;
    }
  }

  // error full rendering for modal forms
  onSaveFailFull(event) {
    if (event.detail[0].body) {
      this.element.innerHTML = event.detail[0].body.innerHTML;
    }
  }

  // field form item selection

  getInputFormItem(event, tag) {
    event.preventDefault();
    const formItem = event.target.closest('.field-value').querySelector(tag);
    return formItem
  }

  // fields update

  updateFilledClassToInputForm(event) {
    const input = this.getInputFormItem(event, 'input');
    // console.log(input);
    this.updateFilledClassToFieldValueBox(input);
  }

  updateFilledClassToSelectForm(event) {
    const select = this.getInputFormItem(event, 'select');
    this.updateFilledClassToFieldValueBox(select);
  }

  updateFilledClassToTextForm(event) {
    const text = this.getInputFormItem(event, 'textarea');
    this.updateFilledClassToFieldValueBox(text);
  }

  updateFilledClassToFieldValueBox(formItem) {
    const length = formItem.value.length;
    const valueBox = formItem.closest('.field-value');
    if (length > 0) {
      valueBox.classList.add('is-filled');
    } else {
      valueBox.classList.remove('is-filled');
    }
    this.updatePasswordCharactersCounter(valueBox, length);
  }

  // fields actions

  clearInputValue(event) {
    const input = this.getInputFormItem(event, 'input');
    input.value = '';
    this.updateFilledClassToFieldValueBox(input);
  }

  clearInputValueAndSubmit(event) {
    const input = this.getInputFormItem(event, 'input');
    input.value = '';
    this.updateFilledClassToFieldValueBox(input);
    this.submitUpdatingRequest();
  }

  // togglePasswordVisibily(event) {
  //   const input = this.getInputFormItem(event,'input');
  //   if (input.type === "password") {
  //     input.type = "text";
  //   } else if (input.type === "text") {
  //     input.type = "password";
  //   }
  // }

  updatePasswordCharactersCounter(valueBox, length) {
    const counter = valueBox.querySelector('.char-counter-field');
    // console.log(counter);
    if (counter) {
      if (length > 0 && length < 12) {
        counter.innerHTML = `<small>${length}/10</small>`;
        counter.classList.remove('good');
      } else if (length > 11) {
        counter.innerHTML = `<small>${length}/10</small>`;
        counter.classList.add('good');
      } else {
        counter.classList.remove('good');
      }
    }
  }

  updateMatchingTest() {
    const confirmationInput = this.element.querySelector('input');
    let checkMatch = false;
    let previousInput = undefined;
    if (this.element.previousElementSibling) {
      previousInput = this.element.previousElementSibling.querySelector('input');
      if (previousInput) {
        checkMatch = (confirmationInput.getAttribute("id") === "user_password_confirmation" && previousInput.getAttribute("id") === "user_password");
      }
    }
    // console.log(`matching test = ${checkMatch}`);
    if (checkMatch) {
      const confirmationValue = confirmationInput.value;
      const previousValue = previousInput.value;
      const matchHint = this.element.querySelector('#psw-car-match');
      // console.log(matchHint);
      if (confirmationValue.length > 0) {
          if (previousValue === confirmationValue) {
            matchHint.classList.add('good');
            matchHint.innerText = "correspondance exacte";
          } else if (previousValue.includes(confirmationValue) ) {
            matchHint.classList.remove('good');
            matchHint.innerText = "saisie en cours";
          } else {
            matchHint.classList.remove('good');
            matchHint.innerText = "pas de correspondance";
          }
      } else {
        matchHint.innerText = "";
      }
    }
  }

  // search actions

  search() {
    // console.log(this.element);
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.element.requestSubmit();
    }, 500)
  }

  searchWithMinChar(event) {
    // console.log(event.currentTarget.value);
    if (event.currentTarget.value && event.currentTarget.value.length > 2) {
      // console.log(this.element);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.element.requestSubmit();
      }, 500)
    }
  }

  searchUpdatingSort() {
    // console.log(this.element);
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.updateSortingSelect('rel');
      this.element.requestSubmit();
    }, 500)
  }

  updateSortingSelect(value) {
    // event.preventDefault();
    const filter = 'sort';
    const filterForm = this.element.querySelector(`select[name='${filter}']`);
    // console.log(filterForm);
    // console.log(filterForm.type);
    if (filterForm.type == 'select-one' && value) {
      filterForm.value = value;
    }
  }

  submit() {
    Rails.fire(this.element, 'submit')
  }

  submitUpdatingRequest() {
    this.element.requestSubmit()
  }

  submitCheckBoxUpdatingRequest(event) {
    // event.preventDefault();
    // console.log(document.querySelectorAll(`input[type='checkbox']`));
    const cb = event.target;
    // console.log(cb);
    // console.log(cb.checked);
    // if (cb.checked) {
    //   // cb.removeAttribute('checked');
    //   cb.checked = true;
    // } else {
    //   // cb.setAttribute('checked', 'checked');
    //   cb.checked = false;
    // }
    // console.log(cb.checked);
    this.element.requestSubmit();
  }

  submitRadioUpdatingRequest(event) {
    // console.log(document.querySelectorAll(`input[type='radio']`));
    const rb = event.target;
    const radioLabels = rb.closest('.display-group-radio').querySelectorAll(`label.group-radio-item`);
    // console.log(radioLabels);
    radioLabels.forEach((label) => {
      label.classList.remove('active');
    });
    rb.closest('label.group-radio-item').classList.add('active');
    this.element.requestSubmit();
  }

  dropFilterAndSubmitUpdatingRequest(event) {
    // event.preventDefault();
    const filterTag = event.target.parentElement;
    const filter = filterTag.dataset.filter;
    // console.log(filter);
    const filterForm = this.element.querySelector(`input[name='${filter}'], select[name='${filter}']`);
    // console.log(filterForm);
    // console.log(filterForm.type);
    if (filterForm.type == 'checkbox') {
      filterForm.checked = false;
    }
    if (filterForm.type == 'select-one') {
      filterForm.value = "";
    }
    // filterForm.value = null;
    // console.log(filterForm);
    this.submitUpdatingRequest();
  }


  dropSelectAndSubmitUpdatingRequest(event) {
    // event.preventDefault();
    const filterTag = event.target.parentElement;
    const select = filterTag.querySelector('select');
    // console.log(select);
    this.submitUpdatingRequest();
  }

}

// OLD SEARCH METHODS

// dropFilter(event) {
//   // event.preventDefault();
//   const filterTag = event.target.parentElement;
//   const filter = filterTag.dataset.filter;
//   console.log(filter);
//   // this.updateFilterInSearchForm();
//   const filterForm = this.formTarget.querySelector(`input[name='${filter}']`);
//   filterForm.checked = false;
//   console.log(filterForm);
//   this.searchLaunch();
//   // this.hideFilterTag();
//   // filterTag.classList.add('d-none');
//   // filterTag.classList.remove('d-flex');
// }

// upResult(event) {
//   console.log(this.displayTarget);
//   const queryString = location.search.substring(1);
//   console.log(queryString);
//   const urlFilter = `/websites/filter?${queryString}`;
//   console.log(urlFilter);
//   Rails.ajax({
//       url: urlFilter,
//       type: 'get',
//       success: function(data) {
//         console.log(typeof(data));
//         console.log(data.body.innerHTML);
//         return this.displayTarget.innerHTML = data.body.innerHTML
//         // if (data.body.innerHTML.length > 0) {
//         // }
//       },
//       error: function(data) {}
//   })

//     // const data = event.detail[0].body.innerHTML
//     // if (data.length > 0) {
//     //   return this.displayTarget.innerHTML = data
//     // }

//     // You could also show a div with something else?
//     // this.displayTarget.innerHTML = '<p>No matching results found</p>'
//   // }

//   updateUrlWithSearchParams(event) {
//     // update location URL with search params
//     const requestUrl = new URL(event.detail[2].responseURL);
//     // console.log(typeof(requestUrl));
//     console.log(requestUrl.search);
//     const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + requestUrl.search;
//     window.history.pushState({ path: newurl }, '', newurl);
//   }

//   result(event) {
//     // event.preventDefault();
//     this.updateUrlWithSearchParams(event);
//     // update search results html
//     const data = event.detail[0].body.innerHTML;
//     if (data.length > 0) {
//       this.displayTarget.innerHTML = data;
//     } else {
//       // You could also show a div with something else?
//       this.displayTarget.innerHTML = '<p>aucun résultat</p>';
//     }
//   }

//   error(event) {
//     console.log(event)
//   }
// }

// // filter() {
// //   const url = `${window.location.pathname}?${this.params}`;

// //   Turbolinks.clearCache();
// //   Turbolinks.visit(url);
// // }

// // get params() {
// //   return this.filterTargets.map((t) => `${t.name}=${t.value}`).join("&");
// // }

// refreshDisplay(event) {
//   const framesToUpdate = this.displayTarget;
//   console.log(framesToUpdate);
//   const content = event.target.parentElement.href;
//   const requestDisplayUrl = new URL(content);
//   console.log(typeof(requestDisplayUrl));
//   console.log(requestDisplayUrl.search);
//   event.preventDefault();
//   update window url
//   console.log(window.location);
//   const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + requestDisplayUrl.search;
//   window.history.pushState({ path: newurl }, '', newurl);
//   // update search results
//   // const data = event.detail[0].body.innerHTML;
//   // if (data.length > 0) {
//   //   return this.displayTarget.innerHTML = data
//   // }
//   // const requestDisplayUrl = new URL(event);
//   const fetchUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '/filter' + requestDisplayUrl.search

//   console.log(fetchUrl);
//   Rails.ajax({
//       url: fetchUrl,
//       type: 'get',
//       success: function(data) {
//         console.log(typeof(data));
//         // console.log(data.body.innerHTML);
//         return framesToUpdate.innerHTML = data.body.innerHTML
//         // if (data.body.innerHTML.length > 0) {
//         // }
//       },
//       error: function(data) {}
//   })
// }

// filter() {
//   const url = `${window.location.pathname}?${this.params}`;

//   Turbolinks.clearCache();
//   Turbolinks.visit(url);
// }

// get params() {
//   return this.filterTargets.map((t) => `${t.name}=${t.value}`).join("&");
// }

