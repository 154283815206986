import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="redirect"
// https://discuss.hotwired.dev/t/redirect-after-turbo-stream-response/2303/2
export default class extends Controller {
  static values = { url: String }
  connect () {
    window.location.href = this.urlValue
  }
}
