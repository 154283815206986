import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['display']

  render(evt) {
    this.displayTarget.innerHTML = evt.detail[0].body.innerHTML
    // const disp = this.displayTarget;
    // console.log(disp);
    // console.log(evt);
    // console.log(evt.detail[0]);
    // const html = evt.detail[0].body.innerHTML;
    // this.displayTarget.innerHTML = html;
  }

  renderAll(evt) {
    this.element.innerHTML = evt.detail[0].body.innerHTML;
  }

  norender(evt) {
    // console.log(evt);
  }
}
