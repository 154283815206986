import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fetch"
export default class extends Controller {
  static targets = ["display"]
  static values = {
    domain: String
  }

  connect() {
  }

  fetchWhoisData() {
    const apiKey = "";
    const domain = this.domainValue;
    // console.log(domain);
    const urlToFetch = `https://whoisapi-whois-v2-v1.p.rapidapi.com/whoisserver/WhoisService?domainName=${domain}&apiKey=${apiKey}&outputFormat=json&da=0&ipwhois=0&thinWhois=0&_parse=0&preferfresh=0&checkproxydata=0&ip=0`
    // console.log(urlToFetch);
    fetch(urlToFetch, {
      "method": "GET",
      "headers": {
        "x-rapidapi-host": "",
        "x-rapidapi-key": ""
      }
    })
    .then(res => res.json())
    .then(data => {
      console.log(data)
    })
    .catch(err => {
      console.error(err);
    });
  }

}
