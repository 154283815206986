import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dismiss"
export default class extends Controller {
  connect() {
  }

  // displays a flash message for a certain period of time
  connect() {
    const element = this.element;
    this.element.classList.add('fade','show');
    // element.removeAttribute('hidden');
    // element.classList.add('animate__animated', 'animate__fadeIn');

    setTimeout(() => {
      this.dismiss();
    }, 5000);
  }

  // the cancel button was pressed or the timer has run down so the message will be removed
  dismiss() {
    // console.log("try dismiss");
    this.element.querySelector(".btn-close").click();
    // wait for the animation fadeOut to end then remove the element
    // this.element.addEventListener('animationend', () => {
    //   this.element.remove();
    // });
  }
}
