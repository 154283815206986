import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = ["btn", "tab"]
  static values = {
    defaultTab: String
  }

  connect() {
    // console.log('hello from tabs controller');
    this.tabTargets.map(x => x.classList.remove("show","active")); // hide all tabs by default
    // OPEN DEFAULT TAB
    // console.log(this.defaultTabValue);
    let selectedBtn = this.btnTargets.find(element => element.dataset.content === this.defaultTabValue);
    let selectedTab = this.tabTargets.find(element => element.dataset.content === this.defaultTabValue);
    if (selectedTab) {
      selectedTab.classList.add("show","active");
    }
    if (selectedBtn) {
      selectedBtn.classList.add("active");
    }
  }

  select(event) {
    // find tab with same id as clicked btn
    let selectedTab = this.tabTargets.find(element => element.dataset.content === event.currentTarget.dataset.content);
    // console.log(selectedTab);
    if (selectedTab.classList.contains("show","active")) {
      // CLOSE CURRENT TAB
      this.tabTargets.map(x => x.classList.remove("show","active")); // hide all tabs
      this.btnTargets.map(x => x.classList.remove("active")); // deactive all btns
    } else {
      // OPEN CURRENT TAB
      this.tabTargets.map(x => x.classList.remove("show","active")); // hide all tabs
      this.btnTargets.map(x => x.classList.remove("active")); // deactive all btns
      selectedTab.classList.add("show","active");; // show current tab
      event.currentTarget.classList.add("active"); // active current btn
    }
  }
}
