import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'connexion', 'connexionBox','connexionButton', 'display', 'messagesBox', 'metadata', 'screenshot']
  static values = {
    connexionButtonUrl: String,
  }

  connect() {
    // console.log("hello from website card controller");
  }

  renderWebsiteCard(evt) {
    // this.updateWebsiteCardOnClick();
    const html = evt.detail[0].body.innerHTML;
    // console.log(html);
    this.element.innerHTML = html;
  }

  norender(evt) {
    console.log(evt);
  }

  renderMetadata(evt) {
    const html = evt.detail[0].body.innerHTML;
    // console.log(this.metadataTarget.innerHTML.length);
    if (this.metadataTarget.innerHTML.length == 0) {
      this.metadataTarget.innerHTML = html;
    } else {
      this.metadataTarget.innerHTML = '';
    }
  }

  toggleScreenshot() {
    this.screenshotTarget.classList.toggle('d-none')
  }

  toggleConnexionBox() {
    this.connexionBoxTarget.classList.toggle('d-none')
  }

  toggleMessagesBox() {
    this.messagesBoxTarget.classList.toggle('d-none')
  }

  updateConnexionAccessButton(evt) {
    // console.log(`conn = ${this.hasConnexionButtonTarget}`);
    if (this.hasConnexionButtonTarget) {
      this.fetch(this.connexionButtonUrlValue, this.connexionButtonTarget);
    }
  }

  fetch (url, element) {
    // console.log(`fetching...${url} into ${element}`)
    fetch(url)
      .then(response => response.text())
      .then(html => {
        // console.log(html);
        element.innerHTML = html;
      })
  }

}

