import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password"
export default class extends Controller {
  static targets = ["field"]

  connect() {
    // console.log("psw controller");
  }

  makeAllVisible() {
    this.fieldTargets.forEach (input => {
      input.type = "text";
    });
  }

  makeAllUnvisible() {
    this.fieldTargets.forEach (input => {
      input.type = "password";
    });
  }
}
