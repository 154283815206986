import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    intersectingDelay: Number,
    lazyLoading: Boolean,
    lazyLoadingDelay: Number,
    lazyLoadingThreshold: Number,
    lazyLoadingRootMargin: String,
    refreshInterval: Number
  }

  connect () {
    if (!this.hasUrlValue) {
      console.error('[stimulus-content-loader] You need to pass an url to fetch the remote content.')
      return
    }
    // console.log(`lazy = ${this.hasLazyLoadingValue}`)
    this.hasLazyLoadingValue ? this.lazyLoad() : this.load()
  }

  disconnect () {
    this.stopRefreshing()
  }

  load () {
    this.fetch()
    if (this.hasRefreshIntervalValue) {
      this.startRefreshing()
    }
  }

  lazyLoad () {
    const delay = this.lazyLoadingDelayValue;
    if (delay) {
      this.fetchAfterDelay(delay)
    } else if (this.hasIntersectingDelayValue) {
        // delayed load if intersecting
        // https://stackoverflow.com/questions/61802983/how-to-delay-intersection-observer-api
        // console.log("intersectingDelay");
        let timeouts = {};
        const options = {
          threshold: this.lazyLoadingThresholdValue,
          rootMargin: this.lazyLoadingRootMarginValue || '0px'
        }
        const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              timeouts[entry.target.id] = setTimeout(() => {
                  this.load()
                  observer.unobserve(entry.target)
              }, this.intersectingDelayValue)
            } else {
                clearTimeout(timeouts[entry.target.id])
            }
          })
        }, options)
        observer.observe(this.element)
    } else {
      // direct load if intersecting
      const options = {
        threshold: this.lazyLoadingThresholdValue,
        rootMargin: this.lazyLoadingRootMarginValue || '0px'
      }
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.load()
            observer.unobserve(entry.target)
          }
        })
      }, options)
      observer.observe(this.element)
    }
  }

  fetch () {
    // console.log(`${this.urlValue}`);
    fetch(this.urlValue)
      .then(response => response.text())
      .then(html => {
        this.element.innerHTML = html
      })
  }

  fetchAfterDelay(delay) {
    const timer = setTimeout(() => {
      this.fetch();
    }, delay);
    clearTimeout(timer);
  }

  startRefreshing () {
    // console.log(`refresh with ${this.refreshIntervalValue}`);
    this.refreshTimer = setInterval(() => {
      this.fetch()
    }, this.refreshIntervalValue)
  }

  stopRefreshing () {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  stopRefresh (event) {
    // event.preventDefault();
    this.refreshIntervalValue = 500000;
    this.stopRefreshing();
    console.log(`stop ${this.refreshIntervalValue}`);
  }

  // addRefresh () {
  //   this.refreshIntervalValue = 500;
  //   console.log(`${this.refreshIntervalValue}`);
  // }

  // refreshIntervalValueChanged() {
  //   console.log(`on refresh change`);
  //   this.stopRefreshing;
  //   this.startRefreshing;
  // }

}
