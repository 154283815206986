import { Controller } from "@hotwired/stimulus"
import Swiper, { Navigation, Pagination } from "swiper";

// Connects to data-controller="viewer-swiper"
export default class extends Controller {
  static targets = ['modal']

  connect() {
    console.log('viewer swiper');
    // const swiper = this.modalTarget.querySelector('.swiper').swiper;
    // console.log(swiper);
    // document.addEventListener('append-slides', () => {
    //   swiper.appendSlides(new SwiperSlide('.new-slide'));
    // });
  }

  // openModalAndInitiateSwiper(evt) {
  //   const active_id = evt.currentTarget.dataset.onstage;
  //   // get target slider index
  //   const indexOnStage = this.getSlideIndexById(active_id) || 0;
  //   // create a promise that resolves when swiper is ready
  //   const swiperReady = new Promise((resolve, reject) => {
  //     const swiper = this.modalTarget.querySelector('.swiper').swiper;
  //     swiper.on('afterInit', resolve);
  //     swiper.on('error', reject);
  //   });

  //   // show modal with bootstrap js
  //   this.openModal();

  //   // wait for swiper to be ready
  //   swiperReady.then(() => {
  //     // check if right slide is active
  //     const swiper = this.modalTarget.querySelector('.swiper').swiper;
  //     const activeSlideIndex = swiper.activeIndex;
  //     const totalSlides = swiper.slides.length;

  //     if (activeSlideIndex === totalSlides - 1) {
  //       // active swiper slide with index
  //       const swiper = this.modalTarget.querySelector('.swiper').swiper;
  //       swiper.slideTo(indexOnStage);
  //     } else {
  //       // do not show modal
  //     }
  //   });
  // }

  openModalAndInitiateSwiper(evt) {
    // console.log(evt);
    // evt.preventDefault();
    const active_id = evt.currentTarget.dataset.onstage;
    // get target slider index
    const indexOnStage = this.getSlideIndexById(active_id) || 0;
    // get swiper object
    const swiper = this.modalTarget.querySelector('.swiper').swiper;
    // active swiper slide with index
    if (swiper) {
      swiper.updateSlides();
      swiper.slideTo(indexOnStage, 0, false);
    }
    // show modal
    this.openModal();
  }

  openModal() {
    document.body.classList.add('modal-open');
    this.modalTarget.style.display = "block";
  }


  // search for slides id and get index position
  getSlideIndexById(id) {
    let index = 0;
    const slides = this.modalTarget.querySelectorAll('.swiper-slide');
    slides.forEach( (slide, posIndex) => {
      if (slide.id == id) {
        index = posIndex;
        return false;
      }
    });
    return index;
  }

  closeModal() {
    document.body.classList.remove('modal-open');
    this.modalTarget.style.display = "none";
  }

}

// connect() {

//   // console.log('hello from viewer-swipe');
//   this.element.addEventListener('touchstart', handleTouchStart, false);
//   this.element.addEventListener('touchmove', handleTouchMove, false);
//   let xDown = null;
//   let yDown = null;
//   // const elementToChange = this.element.querySelector('.com-card-actions');
//   // const previousButton = this.element.querySelector('.com-action.previous');
//   // const nextButton = this.element.querySelector('.com-action.next');
//   // console.log(previousButton);
//   // console.log(this.element);

//   function getTouches(evt) {
//     return evt.touches ||             // browser API
//            evt.originalEvent.touches; // jQuery
//   }

//   function handleTouchStart(evt) {
//       const firstTouch = getTouches(evt)[0];
//       xDown = firstTouch.clientX;
//       yDown = firstTouch.clientY;
//   };

//   function handleTouchMove(evt) {
//       if ( ! xDown || ! yDown ) {
//           return;
//       }

//       let xUp = evt.touches[0].clientX;
//       let yUp = evt.touches[0].clientY;

//       let xDiff = xDown - xUp;
//       let yDiff = yDown - yUp;
//       // console.log(xDiff);

//       if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
//           if ( xDiff > 6 ) {
//               /* right swipe */
//               console.log('swiped left!');
//               // console.log(elementToChange);
//               // console.log(previousButton);
//               // previousButton.click();
//           } else if (xDiff < -6) {
//               /* left swipe */
//               console.log('swiped right!');
//               // console.log(nextButton);
//               // nextButton.click();
//           }
//       } else {
//           if ( yDiff > 0 ) {
//               /* down swipe */
//           } else {
//               /* up swipe */
//           }
//       }
//       /* reset values */
//       xDown = null;
//       yDown = null;
//   };


// }
