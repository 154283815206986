import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="full-screen-modal"
export default class extends Controller {
  connect() {
    console.log('fs_modal');
    document.body.classList.add('modal-open');
  }

  openModal() {
    document.body.classList.add('modal-open');
    this.element.classList.remove('d-none');
  }

  closeModal() {
    document.body.classList.remove('modal-open');
    this.element.classList.add('d-none');
  }
}
